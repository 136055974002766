import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import { MRT_VisibilityState } from 'material-react-table';

export const COLUMN_VISIBILITY: Record<string, MRT_VisibilityState> = {
  CRA: {
    securitizer: true,
    name: true,
    emission: true,
    series: true,
    class: false,
    emissionDate: true,
    product: true,
    typeOfDebtor: false,
    typeOfOffer: false,
    remuneration: false,
    duration: false,
    pulverization: false,
    spread: false,
  },
  CRI: {
    securitizer: true,
    name: true,
    emission: true,
    series: true,
    class: false,
    emissionDate: true,
    product: true,
    typeOfDebtor: false,
    typeOfOffer: false,
    remuneration: false,
    duration: false,
    pulverization: false,
    servicer: false,
    spread: false,
  },
  FII: {
    name: true,
    cnpj: true,
    titleCETIPCode: true,
    product: true,
    typeOfProperty: true,
    administrator: false,
    manager: true,
  },
  FIDC: {
    compare: true,
    name: true,
    cnpj: true,
    np: true,
    product: true,
    condominium: true,
    mm: true,
    administrator: false,
    custodian: false,
    manager: false,
    consultant: false,
  },
};

export const filterMode: any = {
  contains: '$containsi',
  startsWith: '$startsWith',
  endsWith: '$endsWith',
  equals: '$eq',
  notEquals: '$ne',
  between: '$between',
  greaterThan: '$gt',
  greaterThanOrEqualTo: '$gte',
  lessThan: '$lt',
  lessThanOrEqualTo: '$lte',
  empty: '$null',
  notEmpty: '$notNull',
};

const normalizeFilteredValue = (value: any, key: string) => {
  const parseValue = (v: any) => {
    if (!v) return v;
    switch (key) {
      case 'emissionDate':
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(v.toString().trim())) {
          v = v
            .toString()
            .trim()
            .replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3-$2-$1');
        }
        break;
      case 'spread':
        v = parseFloat(
          v.toString().trim().replaceAll('.', '').replaceAll(',', '.'),
        );
        break;
    }

    return v;
  };

  value = Array.isArray(value) ? value.map(parseValue) : parseValue(value);

  return value;
};

export const normalizeFilters = (filters: any, mode: any) => {
  return filters.reduce((obj: any, item: any) => {
    const key = item['id'];
    const value = normalizeFilteredValue(item['value'], key);
    return {
      ...obj,
      [key]: { [filterMode[mode[key]]]: value },
    };
  }, {});
};

export const getIndicatorByMarket = (market: string) => {
  switch (market) {
    case 'CRI':
      return [
        { value: 'cri-emissoes', label: 'Emissões' },
        {
          value: 'cri-perfil-dos-investidores',
          label: 'Perfil dos investidores',
        },
        { value: 'cri-saldo-devedor', label: 'Saldo devedor' },
        { value: 'cri-negociacoes', label: 'Mercado secundário' },
      ];
    case 'CRA':
      return [
        { value: 'cra-emissoes', label: 'Emissões' },
        {
          value: 'cra-perfil-dos-investidores',
          label: 'Perfil dos investidores',
        },
        { value: 'cra-saldo-devedor', label: 'Saldo devedor' },
        { value: 'cra-negociacoes', label: 'Mercado secundário' },
      ];
    case 'FII':
      return [
        { value: 'fii-estoque', label: 'Patrimônio líquido' },
        { value: 'fii-emissoes', label: 'Emissões' },
        { value: 'fii-secundario', label: 'Mercado secundário' },
      ];
    case 'FIDC':
      return [
        { value: 'fidc-estoque', label: 'Patrimônio líquido' },
        { value: 'fidc-emissoes-por-fundo', label: 'Emissões' },
        { value: 'fidc-secundario', label: 'Mercado secundário' },
      ];
    default:
      return [];
  }
};

export const getColumnVisibility = (indicator: string, columns: string[]) => {
  const setHiddenColumns = (colNames: Array<string>) => {
    const hiddenColumns: { [key: string]: boolean } = {};
    colNames
      .filter((colName) => columns.includes(colName))
      .forEach((column) => {
        hiddenColumns[column] = false;
      });
    const visibleColumns: { [key: string]: boolean } = {};
    columns.forEach((column) => {
      visibleColumns[column] = true;
    });
    return {
      ...visibleColumns,
      ...hiddenColumns,
    };
  };
  switch (indicator) {
    case 'cri-emissoes':
    case 'cra-emissoes':
      return setHiddenColumns([
        'classe',
        'remuneracao',
        'spread',
        'prazo',
        'ativoLastro',
        'tipoDevedor',
        'agenteFiduciario',
        'tipoOferta',
        'regimeFiduciario',
        'pulverizacao',
        'quantidadeEmitida',
        'segmentoImobiliario',
        'esg',
        'adm',
        'esgAgencia',
        'documentos',
        'isin',
        'emissao',
        'segmento',
        'cedentes',
        'lider',
      ]);
    case 'cri-perfil-dos-investidores':
    case 'cra-perfil-dos-investidores':
      return setHiddenColumns(['numInvestidores', 'numTitulo']);
    case 'cri-saldo-devedor':
    case 'cra-saldo-devedor':
      return setHiddenColumns(['precoUnitario', 'quantidadeTitulos']);
    case 'cri-negociacoes':
    case 'cra-negociacoes':
      return setHiddenColumns(['titleclass', 'trades']);
    case 'fidc-estoque':
    case 'fii-estoque':
      return setHiddenColumns([
        'tipoAtivo',
        'tipoImovel',
        'tipoEspecialidade',
        'administrador',
        'gestor',
        'gestao',
        'finalidade',
        'dc',
        'cvnp',
        'pdd',
        'np',
        'mm',
        'adm',
        'gest',
        'cust',
        'consultores',
        'ativoLastro',
        'condominio',
        'contrato_lastro',
        'cedentes',
      ]);
    case 'fidc-emissoes-por-fundo':
    case 'fii-emissoes':
      return setHiddenColumns([
        'tipoAtivo',
        'tipoImovel',
        'tipoEspecialidade',
        'np',
        'mm',
        'ativoLastro',
        'condominio',
      ]);
    case 'fidc-secundario':
    case 'fii-secundario':
      return setHiddenColumns(['cota', 'pMin', 'pMax', 'qtd', 'ambiente']);
    default:
      return {};
  }
};

export const getColumnSortingId = (indicator: string) => {
  switch (indicator) {
    case 'fidc-estoque':
    case 'fidc-emissoes-por-fundo':
    case 'fii-emissoes':
      return 'dataRef';
    case 'fidc-secundario':
    case 'fii-secundario':
      return 'dataNeg';
    case 'fii-estoque':
    case 'cra-saldo-devedor':
    case 'cri-saldo-devedor':
      return 'data';
    case 'cra-emissoes':
    case 'cra-perfil-dos-investidores':
    case 'cri-emissoes':
    case 'cri-perfil-dos-investidores':
      return 'dataEmissao';
    case 'cra-negociacoes':
    case 'cri-negociacoes':
      return 'dateneg';
    default:
      return '';
  }
};

export const parseValueToCSVBasicView = (
  key: string,
  value: any,
): string | number => {
  switch (key) {
    case 'np':
      value = value ? 'Não-Padronizado' : 'Padronizado';
      break;
    case 'mm':
      value = value ? 'FIDC MM' : 'Outros';
      break;
    case 'pulverization':
      value = value ? 'Sim' : 'Não';
      break;
    case 'emissionDate':
      value = value ? format(parseISO(value), 'dd/MM/yyyy') : '';
      break;
    case 'spread':
      value = value ? String(value.toFixed(2)).split('.').join(',') : '';
      break;
  }
  return _.isNull(value) ? '' : value;
};
