import { endOfMonth, parseISO, startOfMonth } from 'date-fns';
import qs from 'qs';
import { EventPresentationFilters } from 'src/pages/Event/Schedule';
import { EventFilter, EventSponsor } from 'src/types/event';

import { api } from '../../api/api';

export const getEvents = async ({
  page,
  pageSize,
  filters,
}: {
  page: number;
  pageSize: number;
  filters: EventFilter;
}) => {
  const filterDate =
    filters?.date &&
    typeof filters?.date === 'string' &&
    startOfMonth(parseISO(filters?.date));
  const startDate = filterDate && startOfMonth(new Date(filterDate));
  const endDate = filterDate && endOfMonth(new Date(filterDate));
  const betweenDates = filterDate && [startDate, endDate];
  const state =
    filters?.eventState === 'upcoming'
      ? ['upcoming', 'ongoing']
      : filters?.eventState;

  const query = qs.stringify(
    {
      filters: {
        format: {
          $eqi: filters?.format || undefined,
        },
        eventPresentationType: {
          $eqi: filters?.eventPresentationType || undefined,
        },
        title: {
          $containsi: filters?.search,
        },
        ...(betweenDates
          ? {
              schedules: {
                startDate: {
                  $between: betweenDates,
                },
                endDate: {
                  $between: betweenDates,
                },
              },
            }
          : {}),
        state,
      },
      favoriteFilter: filters?.favorite || undefined,
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/eventos-list?${query}`);
  return data;
};

export const getEvent = async (id: string) => {
  const { data } = await api.get(`/eventos/${id}`);
  return data;
};

export const createEvent = async (formData: any) => {
  return api.post('/eventos', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
};

export const subscribeToEvent = async ({
  id,
  code,
  participantName,
  acceptTermsAndConditions,
}: {
  id: number;
  acceptTermsAndConditions?: boolean;
  code?: string;
  participantName?: string;
}) => {
  await api.patch(`/eventos/${id}/subscribe`, {
    code,
    participantName,
    acceptTermsAndConditions,
  });
};

export const loggedUserIsSubscribed = async (id: number) => {
  const { data } = await api.get(`/eventos/${id}/user-subscribed`);

  return data;
};

export const validateEventInviteCode = async ({
  id,
  code,
}: {
  id: number;
  code?: string;
}) => {
  await api.patch(`/eventos/${id}/validate`, { code });
};

export const getEventSponsors = async (
  eventId: string,
  mode: 'preview' | 'interaction',
) => {
  const query = qs.stringify(
    {
      filters: {
        event: {
          id: eventId,
        },
        category: mode === 'interaction' ? { $ne: 'Apoiadores' } : {},
      },
      populate: ['institution', 'logo'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get<{ data: EventSponsor[] }>(`/event-sponsors?${query}`);
  return data;
};

export const getEventSpeakers = async ({
  page,
  pageSize,
  filters,
}: {
  page: number;
  pageSize: number;
  filters: { eventId: string };
}) => {
  const query = qs.stringify(
    {
      filters: {
        presentations: {
          event: {
            id: filters.eventId,
          },
        },
      },
      pagination: {
        page,
        pageSize,
      },
      sort: ['professional.fullName:asc'],
      populate: {
        poster: true,
        presentations: true,
        professional: {
          populate: {
            photo: true,
            relatedInstitutions: {
              fields: ['fantasyName'],
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/event-speakers?${query}`);
  return data;
};

export const getEventSchedule = async ({
  eventId,
  filters,
  isEnded = false,
}: {
  eventId: string;
  filters: EventPresentationFilters;
  isEnded?: boolean;
}) => {
  const query = qs.stringify(
    {
      pagination: {
        pageSize: 100,
      },
      filters: {
        event: {
          id: eventId,
        },
        state: {
          ...(isEnded
            ? {
                $eq: 'ended',
              }
            : {
                $in: ['upcoming', 'ongoing'],
              }),
        },
        ...(filters.filterSaved
          ? {
              filterSaved: filters.filterSaved,
            }
          : {}),
        ...(filters?.markets?.length
          ? {
              markets: {
                id: {
                  $in: filters.markets,
                },
              },
            }
          : {}),
        ...(filters?.dates?.length
          ? {
              date: {
                $in: filters.dates,
              },
            }
          : {}),
      },
      populate: {
        markets: {
          fields: ['id', 'name'],
        },
        speakers: {
          populate: {
            poster: true,
            professional: {
              fields: ['id', 'fullName', 'slug', 'profileText'],
              populate: ['photo'],
            },
            presentations: {
              fields: ['id', 'name'],
            },
          },
        },
        event: {
          populate: {
            image: true,
          },
        },
      },
      sort: isEnded
        ? ['date:desc', 'startTime:desc']
        : ['date:asc', 'startTime:asc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/event-presentations?${query}`);
  return data;
};

export const getPresentationLive = async (presentationId: string) => {
  const query = qs.stringify(
    {
      populate: {
        markets: {
          fields: ['id', 'name'],
        },
        speakers: {
          populate: {
            poster: true,
            professional: {
              fields: ['id', 'fullName', 'slug', 'profileText'],
              populate: ['photo'],
            },
            presentations: {
              fields: ['id', 'name'],
            },
          },
        },
        event: {
          populate: {
            sponsors: {
              filters: {
                category: {
                  $ne: 'Apoiadores',
                },
              },
              populate: {
                category: true,
                logo: true,
                institution: {
                  fields: ['id', 'fantasyName', 'slug'],
                },
              },
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/event-presentations/${presentationId}?${query}`);
  return data;
};

export const getPresentationsInLiveByEvent = async () => {
  const query = qs.stringify(
    {
      filters: {
        event: {
          state: 'ongoing',
        },
        state: 'ongoing',
      },
      populate: {
        markets: {
          fields: ['id', 'name'],
        },
        speakers: {
          populate: {
            poster: true,
            professional: {
              fields: ['id', 'fullName', 'slug', 'profileText'],
              populate: ['photo'],
            },
            presentations: {
              fields: ['id', 'name'],
            },
          },
        },
        event: {
          populate: {
            sponsors: {
              filters: {
                category: {
                  $ne: 'Apoiadores',
                },
              },
              populate: {
                category: true,
                logo: true,
                institution: {
                  fields: ['id', 'fantasyName', 'slug'],
                },
              },
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/event-presentations/?${query}`);
  return data;
};

export const getUnseenInLiveEvent = async (ids: number[]) => {
  const query = qs.stringify(
    {
      filters: {
        ids,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/event-presentations-ongoing?${query}`);
  return data;
};
